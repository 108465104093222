export default [
  {
    title: 'Anasayfa',
    route: 'home-edit',
    icon: 'HomeIcon',
  },
  {
    title: 'Hakkımızda',
    route: 'about-edit',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Sektörler',
    route: 'sectors-edit',
    icon: 'ClipboardIcon',
  }, 
  {
    title: 'Ürünler',
    route: 'product-edit',
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'Üretim',
    route: 'production-edit',
    icon: 'ShoppingCartIcon',
  },  {
    title: 'Kalite',
    route: 'quality-edit',
    icon: 'StarIcon',
  },  
  {
    title: 'Kvkk',
    route: 'kvkk-edit',
    icon: 'FileIcon',
  },
  {
    title: 'Kullanıcılar',
    route: 'user-edit',
    icon: 'UserIcon',
  },  
  {
    title: 'İletişim',
    route: 'contact-edit',
    icon: 'PhoneIcon',
  },  
]
