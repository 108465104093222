<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <!-- Dil Checkbox -->
    <b-form-checkbox
      name="check-button"
      switch
      v-model="selected"
      class="custom-control-primary mt-1 mr-1"
      @change="onLanguageChange"
    >
      {{ options[0].text }}
    </b-form-checkbox>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">YBM Casting</p>
            <span class="user-status">Admin</span>
          </div>

          <!-- avatarın dinamik hale gelmesi lazım!! -->
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/logo/vuexy-logos.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="goToProfilePage"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profil </span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logOut">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Çıkış</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BFormCheckbox,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import axios from 'axios';

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormCheckbox,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      options: [{ text: "EN" }],
      checkboxState: [],
      selected: localStorage.getItem("language") === "2" ? true : false,
    };
  },
  mounted() {
    this.selected = localStorage.getItem("language") === "2" ? true : false;
  },
  methods: {
    onLanguageChange() {
      const languageCode = this.selected ? 2 : 1;
      console.log("langCode " + languageCode);
      axios
        .get(`api/Contents/GetListByLanguage?language=${languageCode}`)
        .then((response) => {
          this.checkboxState = response.data.data;
          console.log("API'den gelen veri:", response.data);
          localStorage.setItem("language", languageCode.toString());
          location.reload();
        })
        .catch((error) => {
          console.error("API hatası:", error);
        });
    },
    goToProfilePage() {
      this.$router.push("/profile");
    },
    logOut() {
      localStorage.removeItem("token");
      console.log(localStorage.getItem("token"));

      // Sayfayı yeniden yükle
      // window.location.reload();

      this.$router.push("/login");
    },
  },
};
</script>
